import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Tandc = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {isOpen && (
        <div className="max-h-screen fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 ">
          <div className="relative bg-white w-11/12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-lg p-8 max-h-screen overflow-y-auto">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
            >
              &times;
            </button>
            <h2 className="text-3xl font-semibold mb-4 text-center text-sky-600">
              Terms & Conditions{" "}
            </h2>
            <p className="mb-4">
              These Terms and Conditions ("Terms") govern your use of the
              services provided by PICKUPXPRESS ("we," "us," "our"). By
              accessing or using our services, you agree to comply with these
              Terms. Please read them carefully before using our services.{" "}
            </p>
            <h3 className="font-bold mb-2">1. Service Overview</h3>
            <p className="mb-4">
              PICKUPXPRESS offers a range of logistics and shipping services,
              including Full Truckload (FTL), Part Truckload (PTL), e-commerce
              shipping, train cargo, express parcel, air cargo, and
              international shipping. The services provided are subject to
              availability and the specific requirements of each shipment.
            </p>
            {/* <ul className="list-disc ml-5 mb-4">
              <li>
                Personal Information: Such as your name, contact details (phone
                number, email address), billing and shipping addresses, payment
                information, and company identification details if applicable.
              </li>
              <li>
                Service-Related Information: Details related to the shipments
                you book with us, including pickup and delivery addresses,
                package information, and tracking details.
              </li>
              <li>
                Usage Information: Data on how you interact with our website,
                mobile apps, and services, like IP addresses, browser types, and
                pages visited.
              </li>
              <li>
                Communications: Records of your interactions with us, including
                emails, phone calls, and messages sent through our website or
                app.
              </li>
            </ul> */}
            <h3 className="font-bold mb-2">2. User Obligations</h3>
            <p className="mb-4">By using our services, you agree to:</p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Provide accurate and complete information when booking
                shipments, including pickup and delivery addresses, package
                details, and contact information.
              </li>
              <li>
                Ensure that all items shipped comply with legal regulations and
                do not include prohibited or dangerous goods.
              </li>
              <li>
                Package and label your items appropriately to prevent damage
                during transit.
              </li>
              <li>
                Pay all applicable fees and charges for the services provided.
              </li>
              <li>
                Follow any instructions provided by PICKUPXPRESS regarding the
                handling, packaging, and labeling of shipments.
              </li>
            </ul>
            <h3 className="font-bold mb-2">3. Booking and Payment</h3>
            {/* <p className="mb-4">
              We do not sell or trade your personal information to third
              parties. However, we may share your information under the
              following circumstances:
            </p> */}
            <ul className="list-disc ml-5 mb-4">
              <li>
                All bookings must be made through our official channels,
                including our website, mobile app, or customer service center.
              </li>
              <li>
                Payment for services must be made in accordance with the rates
                and payment terms specified at the time of booking.
              </li>
              <li>
                Additional fees may apply for services such as express delivery,
                insurance, and handling of oversized or hazardous items.
              </li>
            </ul>
            <h3 className="font-bold mb-2">4. Cancellation and Refunds</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Cancellations must be made in accordance with our cancellation
                policy, which may vary depending on the type of service booked.
              </li>
              <li>
                Refunds, if applicable, will be processed based on our refund
                policy and may be subject to deductions for administrative fees
                or services already rendered.
              </li>
            </ul>
            <h3 className="font-bold mb-2">5. Shipping and Delivery</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Pickup and delivery times are estimates and may vary due to
                factors beyond our control, such as weather conditions, traffic,
                or customs delays.
              </li>
              <li>
                It is your responsibility to ensure that someone is available at
                the designated pickup and delivery locations to hand over and
                receive the shipment.
              </li>
              <li>
                We reserve the right to refuse or return shipments that do not
                meet our packaging or labeling requirements or contain
                prohibited items.
              </li>
            </ul>
            <h3 className="font-bold mb-2">6. Liability and Insurance</h3>
            {/* <p className="mb-4">
              You may have certain rights regarding your personal information,
              depending on your location. These rights may include:
            </p> */}
            <ul className="list-disc ml-5 mb-4">
              <li>
                PICKUPXPRESS will take all reasonable measures to ensure the
                safe handling and delivery of shipments. However, we are not
                liable for losses, damages, or delays caused by factors beyond
                our control, such as natural disasters, accidents, or
                third-party actions.
              </li>
              <li>
                Optional insurance coverage may be offered for certain
                shipments. If you choose to insure your shipment, the terms and
                conditions of the insurance policy will apply.
              </li>
              <li>
                In the event of loss or damage to a shipment, any claims must be
                submitted in writing within the specified time frame and in
                accordance with our claims process.
              </li>
            </ul>
            <h3 className="font-bold mb-2">7. Prohibited Items</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                The following items are prohibited from being shipped using our
                services: hazardous materials, illegal substances, firearms,
                explosives, perishable goods, and any items restricted by local,
                national, or international laws.
              </li>
              <li>
                We reserve the right to inspect shipments and refuse service if
                prohibited items are found.
              </li>
            </ul>
            <h3 className="font-bold mb-2">8. Intellectual Property</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                All content on the PICKUPXPRESS website, mobile app, and
                promotional materials, including text, graphics, logos, and
                software, is the property of PICKUPXPRESS and is protected by
                intellectual property laws.
              </li>
              <li>
                You may not reproduce, distribute, or use any content from our
                website or services without our prior written consent.
              </li>
            </ul>
            <h3 className="font-bold mb-2">9. Privacy and Data Protection</h3>
            <p className="mb-4">
              We are committed to protecting your privacy and handling your
              personal information in accordance with our Privacy Policy. By
              using our services, you consent to the collection, use, and
              sharing of your information as described in our Privacy Policy.
            </p>
            <h3 className="font-bold mb-2">10. Modification of Terms</h3>
            <p className="mb-4">
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting the updated
              Terms on our website. Your continued use of our services after any
              changes constitutes your acceptance of the revised Terms.
            </p>
            <h3 className="font-bold mb-2">
              11. Governing Law and Dispute Resolution
            </h3>
            <p className="mb-4">
              • These Terms are governed by the laws of the jurisdiction in
              which PICKUPXPRESS operates. Any disputes arising from or related
              to these Terms or our services will be resolved through amicable
              negotiations. If a resolution cannot be reached, disputes may be
              referred to arbitration or a court of competent jurisdiction.
            </p>
            <div className="card  bg-sky-600 shadow-lg rounded-lg p-4">
              <h3 className="text-white text-lg  font-bold mb-2">Contact Us</h3>
              <p className="text-base text-white font-bold">
                If you have any questions or concerns regarding these Terms,
                please contact us at:
              </p>
              <p className="text-base text-white mt-4">
                <strong> Email:</strong> support@pickupepxress.com
              </p>
              <p className="text-base text-white mt-1">
                <strong> Address:</strong> C-702 Ithum Towers Noida Sector
                62-U.P.
              </p>
              <p className="text-base text-white mt-1">
                <strong>Phone:</strong> +91-8707000087
              </p>
            </div>
            <p className="text-base mt-4 flex items-center">
              <input
                id="privacy-checkbox"
                type="checkbox"
                checked={isChecked} // Bind checkbox to state
                onChange={handleCheckboxChange} // Handle change event
                className=" -mt-10 w-10 h-10 text-2xl text-sky-600 bg-gray-100 "
              />
              <label htmlFor="privacy-checkbox" className="ml-2">
                By using PICKUPXPRESS services, you acknowledge that you have
                read, understood, and agree to these Terms and Conditions.
              </label>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Tandc;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { z } from "zod";
import toast from "react-hot-toast";
import rate from "../assets/rate.gif";

const rateCalculatorSchema = z.object({
  pickupPincode: z
    .string()
    .length(6, "Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Pincode must be numeric"),
  dropPincode: z
    .string()
    .length(6, "Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Pincode must be numeric"),
  length: z
    .string()
    .min(1, "Length is required")
    .regex(/^\d+$/, "Length must be positive"),
  width: z
    .string()
    .min(1, "Width is required")
    .regex(/^\d+$/, "Width must be positive"),
  height: z
    .string()
    .min(1, "Height is required")
    .regex(/^\d+$/, "Height must be positive"),
  weight: z
    .string()
    .min(1, "Weight is required")
    .regex(/^\d+$/, "Weight must be positive"),
  shipmentType: z.string().optional(),
  declaredValue: z
    .string()
    .optional()
    .refine(
      (value) => value === "" || (!isNaN(Number(value)) && Number(value) >= 0),
      {
        message: "Declared Value must be a non-negative number",
      }
    ),
  prepaid: z.string().nonempty({ message: "Prepaid/COD is required" }),
  cod: z.string().optional(),
});

const RateCalculator = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState();
  const [prepaidValue, setPrepaidValue] = useState("");
  const [loader, setLoader] = useState(false);
  const rateenv = process.env.REACT_APP_API_KEY;

  const handlePrepaidChange = (e) => {
    setPrepaidValue(e.target.value);
  };

  console.log("data", data);

  const handleInputChange = (event) => {
    const { value, id } = event.target;
    if (
      [
        "pickupPincode",
        "dropPincode",
        "weight",
        "length",
        "width",
        "height",
        "cod",
      ].includes(id)
    ) {
      const filteredValue = value.replace(/[^0-9]/g, "");
      event.target.value = filteredValue;
    }
  };

  const handleViewClick = () => {
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(rateCalculatorSchema),
  });

  const onSubmit = async (formData) => {
    setLoader(true);
    const requestBody = {
      from_pincode: formData.pickupPincode,
      to_pincode: formData.dropPincode,
      length: parseFloat(formData.length),
      width: parseFloat(formData.width),
      height: parseFloat(formData.height),
      weight: parseFloat(formData.weight),
      shipment_type: formData.shipmentType,
      declared_value: formData.declaredValue
        ? parseFloat(formData.declaredValue)
        : "",
      prepaid: formData.prepaid,
      cod_amount: formData.prepaid === "cod" ? parseFloat(formData.cod) : "",
    };

    debugger;
    try {
      const response = await axios.post(rateenv, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "success") {
        setData(response.data);
        reset();
        handleViewClick();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div>
        <div className="block w-full mt-3 max-w-screen-xl px-4 mx-auto text-black bg-white border rounded-lg shadow-md  border-white/80 bg-opacity-80  lg:px-8 lg:py-4 py-12">
          <div className="container p-4 overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className=" w-full text-gray-800 p-4 md:p-8 dark:text-gray-800 shadow-lg dark:bg-gray-100 rounded-lg">
              <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-sky-600 px-2 sm:px-4 py-2 sm:py-1 h-[40px] w-[60%] sm:w-full flex items-center justify-center">
                Rate Calculator
              </button>
              <form
                className="space-y-6 font-semibold"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="pickupPincode"
                      className="block text-sm mb-1"
                    >
                      Pickup Pincode <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="6"
                      id="pickupPincode"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.pickupPincode ? "border-red-500" : ""
                      }`}
                      {...register("pickupPincode")}
                      onChange={handleInputChange}
                    />
                    {errors.pickupPincode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.pickupPincode.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="dropPincode" className="block text-sm mb-1">
                      Drop Pincode <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="dropPincode"
                      maxLength="6"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.dropPincode ? "border-red-500" : ""
                      }`}
                      {...register("dropPincode")}
                      onChange={handleInputChange}
                    />
                    {errors.dropPincode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.dropPincode.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label className="block text-sm mb-1">
                      Dimensions (in Cms){" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      <input
                        type="text"
                        id="length"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.length ? "border-red-500" : ""
                        }`}
                        placeholder="L"
                        {...register("length")}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        id="width"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.width ? "border-red-500" : ""
                        }`}
                        placeholder="W"
                        {...register("width")}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        id="height"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.height ? "border-red-500" : ""
                        }`}
                        placeholder="H"
                        {...register("height")}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.length && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.length.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm mb-1">
                      Weight (in kgs) <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="weight"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.weight ? "border-red-500" : ""
                      }`}
                      {...register("weight")}
                      onChange={handleInputChange}
                    />
                    {errors.weight && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.weight.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="shipmentType"
                      className="block text-sm mb-1"
                    >
                      Shipment Type{" "}
                      <span className="text-gray-600">(Optional)</span>
                    </label>
                    <select
                      id="shipmentType"
                      className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                      {...register("shipmentType")}
                    >
                      <option value="parcel">Regular</option>
                      <option value="document">Express</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="prepaid" className="block text-sm mb-1">
                      Prepaid/COD <span className="text-red-600">*</span>
                    </label>
                    <select
                      id="prepaid"
                      value={prepaidValue}
                      onChange={handlePrepaidChange}
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.prepaid ? "border-red-500" : ""
                      }`}
                      {...register("prepaid")}
                    >
                      <option value="prepaid">Prepaid</option>
                      <option value="cod">COD</option>
                    </select>
                    {errors.prepaid && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.prepaid.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="declaredValue"
                      className="block text-sm mb-1"
                    >
                      Declared Value{" "}
                      <span className="text-gray-600">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      id="declaredValue"
                      className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                      {...register("declaredValue")}
                      onChange={handleInputChange}
                    />
                    {errors.declaredValue && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.declaredValue.message}
                      </p>
                    )}
                  </div>

                  {prepaidValue === "cod" && (
                    <div>
                      <label htmlFor="cod" className="block text-sm mb-1">
                        COD Amount
                      </label>
                      <input
                        type="text"
                        id="cod"
                        className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                        {...register("cod")}
                        onChange={handleInputChange}
                      />
                      {errors.cod && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.cod.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className="w-full flex justify-center">
                  <button
                    type="submit"
                    className="bg-sky-600 text-white px-4 py-2 rounded-lg hover:bg-sky-500 flex items-center justify-center"
                    disabled={loader}
                  >
                    {loader ? (
                      <img src={rate} alt="Loading..." className="h-6" />
                    ) : (
                      "Calculate Rate"
                    )}
                  </button>
                </div>
              </form>
            </div>
            {data && (
              <div className="overflow-x-auto">
                <table className="min-w-full mt-6 bg-white shadow-md rounded-lg">
                  <thead className="bg-sky-600 text-white">
                    <tr>
                      <th className="px-4 py-2 text-left">From Pincode</th>
                      <th className="px-4 py-2 text-left">To Pincode</th>
                      <th className="px-4 py-2 text-left">Zone</th>
                      <th className="px-4 py-2 text-left">Weight (kg)</th>
                      <th className="px-4 py-2 text-left">Carrier</th>
                      <th className="px-4 py-2 text-left">Service Type</th>
                      <th className="px-4 py-2 text-left">Total Price</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    <tr className="bg-gray-100 border-b hover:bg-gray-200">
                      <td className="border px-4 py-2">
                        {data.data.from_pincode}
                      </td>
                      <td className="border px-4 py-2">
                        {data.data.to_pincode}
                      </td>
                      <td className="border px-4 py-2">{data.data.zone}</td>
                      <td className="border px-4 py-2">{data.data.weight}</td>
                      <td
                        colSpan="3"
                        className="border px-4 py-2 text-center font-semibold"
                      >
                        Pricing Information Below
                      </td>
                    </tr>

                    {Object.entries(data.data.partners_pricing).map(
                      ([partnerName, services]) =>
                        Object.entries(services).map(
                          ([serviceType, pricing]) => (
                            <tr
                              key={`${partnerName}-${serviceType}`}
                              className="border-b hover:bg-gray-100 transition duration-300"
                            >
                              <td className="border px-4 py-2"></td>
                              <td className="border px-4 py-2"></td>
                              <td className="border px-4 py-2"></td>
                              <td className="border px-4 py-2"></td>
                              <td className="border px-4 py-2 font-semibold">
                                {partnerName}
                              </td>
                              <td className="border px-4 py-2">
                                {serviceType}
                              </td>
                              <td className="border px-4 py-2 font-bold text-sky-600">
                                {pricing.cod_total_price}
                              </td>
                            </tr>
                          )
                        )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCalculator;
